import React from "react"
import { Link } from "gatsby"
import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles({
        link: {
            color: 'white',
            textDecoration: 'none',
            '&:hover ': {
                'border-bottom': '2px solid',
                'padding-bottom': '5px'
            }
        },
        linkActive: {
            'border-bottom': '2px solid',
            'padding-bottom': '5px'

        }
})

const ListLink = props => {
    const classes = useStyles()
    return (
        <li style={{ display: `inline-block`, margin: `1rem` }}>
            <Link partiallyActive={props.partially} className={classes.link} activeClassName={classes.linkActive} to={props.to}>
                {props.children}
            </Link>
        </li>
    )
}

export default function NavBar() {
    return (
        <nav>
            <ul style={{ listStyle: `none`, width: '100%', float: `right` }}>
                <ListLink to="/" partially={false}>Home</ListLink>
                <ListLink to="/projects" partially={true}>Projects</ListLink>
                <ListLink to="/articles" partially={true}>Articles</ListLink>
            </ul>
        </nav>
    )
}
