import React from "react"
import { createUseStyles } from 'react-jss'
import NavBar from "./navbar"
import Footer from "./footer"

const useStyles = createUseStyles({
    '@global': {
        body: {
            'display': 'flex',
            'min-height': '100%',
            'flex-direction': 'column',
            'background-color': '#02182B',
            'color': 'white',
        }
    },
    layout: {
        'flex': '1',
        'padding': '1rem'
    }
})

export default function Layout({ style, children, showFooter = true }) {
    const classes = useStyles()
    let footer;
    if (showFooter) {
        footer = <Footer/>
    }
    return (
        <div className={classes.layout}>
            <NavBar/>
            <div className={style}>
                {children}
            </div>
            {footer}
        </div>
    )
}
