import React from "react"
import { createUseStyles } from 'react-jss'
import Layout from "../components/layout"

const useStyles = createUseStyles({
    layout: {
      'padding': '1rem',
    },
})

export default function NotFound() {
    const classes = useStyles()
    return (
    <Layout style={classes.layout}>
        <h1>Page Not Found</h1>
        <p>Oops, couldn't find this page! Maybe you took a wrong turn?</p>
    </Layout>
    )
}
