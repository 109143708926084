import React from "react"
import { createUseStyles } from 'react-jss'
import { FaEnvelope, FaLinkedin, FaInstagram, FaTwitter } from 'react-icons/fa'

const useStyles = createUseStyles({
  footer: {
      //'display': 'flex',
      //'margin-top': 'auto',
      'font-size': '15px',
  },
  designed: {
      'display': 'flex',
      'align-items': 'center',
      'margin-bottom': '0',
      'height': '30px',
      'margin': '0.125rem 0.625rem'
  },
  socialMedia: {
      'display': 'flex',
      'justify-content': 'center',
      'margin-bottom': '0',
      'font-size': '25px',
      'line-height': '25px',
  },
  icon: {
    'color': 'white',
    'margin': '5px 5px 0px 5px',
    '&:hover': {
      'border-bottom': '2px solid',
      'background': 'transparent'
    }
    },
    'list': {
        'display': 'flex',
        'justify-content': 'center',
        'flex-wrap': 'wrap',
        'white-space': 'nowrap',
        'list-style': 'none',
        'margin-left': '0',
        'margin-bottom': '0'
    },
})

export default function Footer() {
    const classes = useStyles()
    return (
        <footer className={classes.footer}>
            <ul className={classes.list}>
              <li className={classes.socialMedia}>
                  <a className={classes.icon} aria-label="Mail" href="mailto:me@antonioi.dev">
                    <FaEnvelope />
                  </a>
                  <a className={classes.icon} aria-label="LinkedIn" href="https://uk.linkedin.com/in/antonioi">
                    <FaLinkedin />
                  </a>
              </li>
              <li className={classes.designed}>Designed by Antonio Iuliano.</li>
            </ul>
        </footer>
    )
}
